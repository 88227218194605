export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const filterImage = require("../assets/filter.png");
export const sortImage = require("../assets/sort.png");
export const SELECTED_HEART = require("../assets/selectedHeart.png");
export const UN_SELECTED_HEART = require("../assets/unselectedHeart.png");
export const CHECKED = require("../assets/Check/Group.png");
export const UNCHECKED = require("../assets/Unchecked/Rectangle29Copy.png");
export const Cart = require("../assets/cartBlack.png");
export const Notification = require("../assets/notificationsIcon.png");
export const faceBook = require("../assets/facebook.png");
export const googlePlus = require("../assets/googlePlus.png");
export const changePasswordIcon = require("../assets/changePasswordIcon.png");
export const backIcon = require("../assets/backIcon.png");
export const cameraIcon = require("../assets/cameraIcon.png");
export const whiteCameraIcon = require("../assets/whiteCamera.png");
export const crossIcon = require("../assets/crossIcon.png");
export const cameraIcons = require("../assets/cameraIcons.png");
export const galleryIcon = require("../assets/galleryIcon.png");
export const wishListIcon = require("../assets/wishListIcon.png");
export const myOrdersIcon = require("../assets/myOrdersIcon.png");
export const savedIcon = require("../assets/savedIcon.png");
export const connectedIcon = require("../assets/connectedIcon.png");
export const helpIcon = require("../assets/helpIcon.png");
export const NotificationProfileIcon = require("../assets/notifProfile.png");
export const NotificationOnIcon = require("../assets/notifOn.png");
export const NotificationOffIcon = require("../assets/notifOff.png");
export const logoutIcon = require("../assets/logoutIcon.png");
export const password = require("../assets/password.png");


export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/downArrow.png");
export const rightArrow = require("../assets/rightArrow.png");
export const notificationIcon = require("../assets/notificationsIcon.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const addressImage = require('../assets/address.png');
export const imageLock = require('../assets/Group 4308.svg');
export const cameraImg = require('../assets/photo.png');
export const profileCloseImg = require('../assets/Page 1.svg');
export const closeImg = require('../assets/Path 10545.svg');
export const imgaeCamera = require('../assets/photo-camera.svg');
export const emptyProfile = require('../assets/user.png');
export const profileImg = require('../assets/user-pics.png');
export const closeIcon = require('../assets/Page 1 (1).svg');



//Images
export const emptyNotificationImg = require('../assets/notification-icn.png');
export const listNotificationImg = require('../assets/notify-icn.png');
export const deleteNotificationImg = require('../assets/delete-icn.png');

export const buildImg = require('../assets/flats.svg');
export const listAddressImg = require('../assets/address-icn.png');
export const checkCheck = require('../assets/Group 3945.svg');
export const emptyCheck = require('../assets/Ellipse 77.svg');


export const fbIcon = require('../assets/facebook.png');
export const GoogleIcon = require('../assets/googlePlus.png');

export const emptyOrdersAndWishlistImg = require('../assets/no-order-icn.png');

export const orderStatucheck = require('../assets/statuss-check.png');

//menu icons
export const wishlistImg = require('../assets/whish-list-icn.png');
export const orderImg = require('../assets/my-order-icn.png');
export const addressImg = require('../assets/address-icn.png');
export const connectedImg = require('../assets/connected-icn.png');
export const helpcenterImg = require('../assets/help-icn.png');
export const notificationImg = require('../assets/notify-icn.png');
export const logoutImg = require('../assets/logout-icn.png');













