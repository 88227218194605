Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";

exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";



//REgistartion API's
exports.sendEmailOTPAPIEndPoint = "account/accounts/send_otp";
exports.guestRegistrationAPIEndPoint = "account/accounts";
exports.registrationThorughEmailAPIEndPoint = "account/accounts/send_otp";
exports.registerEmailVerifyAPIEndPoint = "account_block/accounts/verify_email";

//helpcenter API's
exports.getHelpCenterAPIEndPoint = 'help_center/help_centers';

// social login
exports.socialRegistartionAPIEndPoint =
  "social_media_account/social_auths/social_login";
