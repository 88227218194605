// Customizable Area Start
export const emptyCart =require("../assets/images/cart-empty-icn.png");
export const checkCheck = require('../assets/images/Group 3945.svg');
export const addressImage = require('../assets/images/address.png');
export const closeImg = require('../assets/images/Path 10545.svg');
export const emptyCheck = require('../assets/images/Ellipse 77.svg');
export const closeIcon = require('../assets/images/Page 1 (1).svg');
export const fbIcon =require('../assets/images/facebook.png');
export const GoogleIcon = require('../assets/images/googlePlus.png');
export const backIcon = require("../assets/images/backIcon.png");
export const notificationIcon = require("../assets/images/notificationsIcon.png");
export const checkboxSelected = require("../assets/images/selectedCheckbox.png");
export const checkboxUnSelected = require("../assets/images/checkboxUnselected.png");
export const blankAddress = require("../assets/images/blankAddress.png");
export const tickAddress = require("../assets/images/tickAddress.png");
export const cartEmpty = require("../assets/images/cartEmpty.png");
export const couponTick = require("../assets/images/couponTick.png");
// Customizable Area End