//@ts-ignore
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    product?: any;
    suscribeProductData?: any;
    subscriptionqty?: any;
    selectedPackageName?: any;
    selectedSubscribePackage?: any;
    isSubscribeClicked?: boolean;
    avaiableTimeSlotName?: any;
    TimeslotList?: any;
    productDetails?: any;
    productSlaeprice?: any;
    productOnSale?: any;
    ProductPrice?: any;
    SubscriptionRequestBody?: any;

    //functions list
    toggleSubscribe?: any;
    updateSubscriptionQty?: any;
    subscriptionPackages?: any;
    handleChangePackage?: any;
    handleSelectedTimeSlot?: any;
    addToCartWithSubscription?: any;
    handleTimeslot?: any;
    // Customizable Area End

}

interface S { }

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End

}

export default class SchedulingController extends BlockComponent<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            // Customizable Area Start
            // Customizable Area End
        ];
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    };

    async componentDidMount() {
        // Customizable Area Start
        // Customizable Area End
    }

    async componentWillUnmount() {
        super.componentWillUnmount();
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) { }
}