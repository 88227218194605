//@ts-ignore
import React, { Fragment } from "react";
import { withRouter } from "react-router-dom";
import { Button, Form, FormGroup, Modal, ModalBody, ModalHeader, ModalFooter, Input } from "reactstrap";
import { FaPlus, FaMinus } from "react-icons/fa";

//subscribe css
import '../assets/css/scheduling.css';
//@ts-ignore
import content from "../../../components/src/content.js"
import { closebutton } from "./assets";
import SchedulingController, { Props } from './SchedulingController.web';

// Customizable Area Start
// Customizable Area End

export class Scheduling extends SchedulingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    };
    async componentDidMount() {
        // Customizable Area Start
        window.scrollTo(0, 0);
        // Customizable Area End
    };
    render() {
        // Customizable Area Start
        return (
            /* Modal for Subscription Start */
            <Modal isOpen={this.props?.isSubscribeClicked} toggle={this.props?.toggleSubscribe} centered={true} className="cm-small-modal-6" >
                <ModalHeader className="edit-add-addr-title-bar p-4" close={<img src={closebutton} style={{ cursor: "pointer" }} alt={""} onClick={this.props?.toggleSubscribe} />}>
                    <span>{content.subscription}</span>
                </ModalHeader>
                <ModalBody className="py-4 px-5 yt-add-modal-body">
                    <div className="right-price-sec">
                        {this.props?.product?.stock_qty >= 1 &&
                            (
                                <div className="sp-price-right-content">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <p className="m-0 pr-2 subscription-qty"> {content.quantity} </p>
                                        <div className="sp-quantity-box">
                                            <div className="cart-quantity-field">
                                                <Form>
                                                    <FormGroup className="m-0">
                                                        <span className="cart-quantity-icn quantity-icn-left d-flex align-items-center p-2">
                                                            <FaMinus className="cart-quantity-minus" onClick={() => {
                                                                this.props?.updateSubscriptionQty('', '');
                                                            }}
                                                            />
                                                        </span>
                                                        <input type="number" className="form-control border-0" id="cart-quantity-123" value={this.props?.subscriptionqty} />
                                                        <span className="cart-quantity-icn quantity-icn-right d-flex align-items-center p-2">
                                                            <FaPlus className="cart-quantity-plus" onClick={() => {
                                                                this.props?.updateSubscriptionQty(this.props?.product.stock_qty, 'Add');
                                                            }}
                                                            />
                                                        </span>
                                                    </FormGroup>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                    <hr style={{ border: "solid 1px #cae2fe" }} />
                    <div className="sp-price-right-content">
                        <p className="quantity pr-2 subscribePackageText">{content.subscriptionPackage}</p>
                        <div className='row col-12'>
                            {this.props?.suscribeProductData?.attributes?.available_subscription.daily && this.props?.suscribeProductData?.attributes?.available_subscription.daily.length > 0 &&
                                (
                                    <div className="col-4">
                                        <Input style={{ border: "solid 1px #8b8f95" }} className="radio-btn" type="radio"
                                            name="subPackage" value="daily" checked={this.props?.selectedPackageName == "daily" ? true : false}
                                            onChange={() => {
                                                this.props?.subscriptionPackages(this.props?.suscribeProductData.attributes.available_subscription.daily, "daily")
                                            }}
                                        />
                                        <span className="radio-btn-text">{content.daily}</span>
                                    </div>
                                )}
                            {this.props?.suscribeProductData?.attributes?.available_subscription.weekly && this.props?.suscribeProductData?.attributes?.available_subscription.weekly.length > 0 &&
                                (
                                    <div className="col-4">
                                        <Input style={{ border: "solid 1px #8b8f95" }} className="radio-btn" type="radio" name="subPackage"
                                            value="weekly" checked={this.props?.selectedPackageName == "weekly" ? true : false}
                                            onChange={() => {
                                                this.props?.subscriptionPackages(this.props?.suscribeProductData.attributes.available_subscription.weekly, "weekly")
                                            }}
                                        />
                                        <span className="radio-btn-text">{content.weekly}</span>
                                    </div>
                                )}
                            {this.props?.suscribeProductData?.attributes?.available_subscription.monthly && this.props?.suscribeProductData?.attributes?.available_subscription.monthly.length > 0 &&
                                (
                                    <div className="col-4">
                                        <Input style={{ border: "solid 1px #8b8f95" }} className="radio-btn" type="radio"
                                            name="subPackage" value="monthly" checked={this.props?.selectedPackageName == "monthly" ? true : false}
                                            onChange={() => {
                                                this.props?.subscriptionPackages(this.props?.suscribeProductData.attributes.available_subscription.monthly, "monthly")
                                            }}
                                        />
                                        <span className="radio-btn-text">{content.monthly}</span>
                                    </div>
                                )}
                        </div>
                        <br />
                        <p className="quantity subscriptionPeriod pr-2 mt-4">{content.subscriptionPeriod}</p>
                        {/* @ts-ignore */}
                        <div className="row mt-1" id="mySelect" style={this.props?.selectedSubscribePackage && this.props?.selectedSubscribePackage.length > 0 ? { cursor: 'pointer' } : { cursor: 'default' }}>
                            <select name='subscriptionPeriod' className='form-control select-input Pcakages-subscription'
                                disabled={(this.props?.selectedSubscribePackage && this.props?.selectedSubscribePackage.length > 0 || this.props?.suscribeProductData?.attributes?.subscription_period) ? false : true}
                                style={this.props?.selectedSubscribePackage && this.props?.selectedSubscribePackage.length > 0 ? { cursor: 'pointer' } : { cursor: 'default' }}
                                onClick={(e) => { this.props?.handleChangePackage(e) }}>
                                {this.props?.selectedSubscribePackage && this.props?.selectedSubscribePackage.length > 0
                                    ?
                                    (
                                        <>
                                            <option value=''>Select One</option>
                                            {this.props?.selectedSubscribePackage.map((ele: any, index: number) => (
                                                <option key={index} value={ele.value}>
                                                    {ele.value} Months
                                                </option>
                                            ))}
                                        </>
                                    ) :
                                    (<option>{this.props?.suscribeProductData?.attributes?.subscription_period} Months</option>)
                                }
                            </select>
                        </div>
                    </div>
                    <br />
                    {this.props?.avaiableTimeSlotName && this.props?.avaiableTimeSlotName.length > 0
                        ?
                        (
                            <div className="sp-price-right-content">
                                <p className="quantity subscription-timeslot pr-2">{content.subscriptionTimeslot}</p>
                                <div className="row col-12">
                                    {this.props?.avaiableTimeSlotName.map((myTimeSlot: any, index: number) => (
                                        <div className="col-4">
                                            <Input style={{ border: "solid 1px #8b8f95" }} className="radio-btn" type="radio" name="preferredTime"
                                                value={myTimeSlot.type} onChange={(e) => this.props?.handleTimeslot(e)}
                                            />
                                            <span className="radio-btn-text">{myTimeSlot.value}</span>
                                        </div>
                                    ))}
                                </div>
                                <div className="row mt-2">
                                    <select name="timeSlots" className="form-control select-input Pcakages-subscription"
                                        disabled={this.props?.TimeslotList && this.props?.TimeslotList ? false : true}
                                        onChange={(e) => this.props?.handleSelectedTimeSlot(e)}
                                    >
                                        <option value=''>Select One</option>
                                        {/* @ts-ignore */}
                                        {this.props?.TimeslotList?.slotName?.map((ele: any, index: number) => (
                                            <>
                                                {/* @ts-ignore */}
                                                <option value={[ele, this.props?.TimeslotList.discount]} key={index}>{ele}</option>
                                            </>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        )
                        :
                        this.props?.productDetails?.attributes?.preferred_delivery_slot &&
                        this.props?.selectedSubscribePackage?.length == 0
                        &&
                        (
                            <div className="sp-price-right-content">
                                <p className="quantity subscription-timeslot pr-2">{content.subscriptionTimeslot}</p>
                                <div className="row col-12">
                                    <div className="col-4">
                                        <Input style={{ border: "solid 1px #8b8f95" }} className="radio-btn" type="radio" name="preferredTime"
                                            value={["9am to 12pm", "6am to 9am"].includes(this.props?.productDetails?.attributes?.preferred_delivery_slot)
                                                ? "Morning "
                                                : "Evening "}
                                            checked
                                        />
                                        <span className="radio-btn-text">
                                            {
                                                ["9am to 12pm", "6am to 9am"].includes(this.props?.productDetails?.attributes?.preferred_delivery_slot)
                                                    ? "Morning "
                                                    : "Evening "
                                            }
                                        </span>
                                    </div>
                                </div>
                                <div className="row mt-2">
                                    <select name="timeSlots" className="form-control select-input Pcakages-subscription">
                                        <option value={this.props?.productDetails?.attributes?.preferred_delivery_slot} key={1}>
                                            {this.props?.productDetails?.attributes?.preferred_delivery_slot}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        )
                    }
                    < hr style={{ border: "solid 1px #cae2fe" }} />
                    <div className="sp-price-right-content">
                        <div className="col d-flex">
                            <p className="quantity sp-quantity-tag-name pr-2">{content.totalPrice} : </p>
                            <p className="price pr-2">
                                {/* @ts-ignore */}
                                {JSON.parse(localStorage.getItem('countryCode'))?.countryCode}{" "}
                                {this.props?.productOnSale
                                    ?
                                    // @ts-ignore
                                    parseFloat((this.props?.productSlaeprice) * this.props?.subscriptionqty).toFixed(2) :
                                    // @ts-ignore
                                    parseFloat((this.props?.ProductPrice) * this.props?.subscriptionqty).toFixed(2)
                                }
                            </p>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className='justify-between d-flex flex-row'>
                    <Fragment>
                        <ul style={{ listStyle: "none", padding: 0 }} className='justify-between d-flex flex-row'>
                            <li className="mx-2">
                                <Button style={{ width: "100%" }} type="button" className="primary-btn"
                                    disabled={!this.props?.SubscriptionRequestBody ? true : false}
                                    onClick={() => {
                                        this.props?.addToCartWithSubscription();
                                    }}
                                >
                                    {content.addToCart}
                                </Button>
                            </li>
                            <li style={{ padding: "1rem" }}></li>
                            <li className="mx-2">
                                <Button type="button" disabled={!this.props?.SubscriptionRequestBody ? true : false}
                                    className="primary-btn" onClick={() => {
                                        localStorage.setItem("buyNow",
                                            JSON.stringify(
                                                {
                                                    cat_id: this.props?.productDetails.id, quantity: this.props?.subscriptionqty,
                                                    subscriptionReqBodyData: this.props?.SubscriptionRequestBody
                                                }
                                            )
                                        ),
                                            //@ts-ignore
                                            this.props?.history?.push("/cart")
                                    }}
                                >
                                    {content.buyNow}
                                </Button>
                            </li>
                        </ul>
                    </Fragment>
                </ModalFooter>
            </Modal>
            /* Modal for Subscription End */
        );
        // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
};

//@ts-ignore
export default withRouter(Scheduling);